@import '../../scss/custom.scss';

.products-listing-component {
  .card {
    .card-img-top {
      height: 17rem;
      object-fit: cover;
    }

    .position-absolute {
      bottom: 0;
      left: 0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .products-listing-component {
    .card {
      .card-img-top {
        height: 12rem;
      }
    }
  }
}
