@import '../../scss/custom.scss';

.heading-component {
  .image-wrapper {
    top: 0;
    bottom: 0;
    right: 0;
    left: 50%;
    width: 99.4vw;
    margin-left: -50vw;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .header-1 {
    font-size: 4rem;

    @media (max-height: 400px) {
      font-size: 2rem;
    }
  }
}
