@import '../../scss/custom.scss';

.footer-component {
  img {
    max-width: 9.375rem;
    height: auto;
  }

  .links-wrapper {
    li {
      list-style: none;
    }
  }

  .social-icons {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }
}
