@import '../../scss/custom.scss';

.carousel-component {
  .carousel {
    max-width: 50rem;
    .carousel-inner {
      .carousel-item {
        img {
          object-fit: cover;
          max-height: 25rem;
        }
        &.active {
          img {
            max-height: 25rem;
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .carousel-component {
    .carousel {
      .carousel-inner {
        .carousel-item {
          img {
            max-height: 25rem;
          }
          &.active {
            img {
              max-height: 25rem;
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .carousel-component {
    .carousel {
      width: 50rem;
      .carousel-inner {
        .carousel-item {
          img {
            height: 28.125rem;
          }
          &.active {
            img {
              height: 28.125rem;
            }
          }
        }
      }
    }
  }
}
